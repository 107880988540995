import React from 'react'
import {BirthdayContainerNew, CommonContainer, DataThemeGrid, DataThemeGridNew, MoreMonthData, ThemeCard,ThemeGrid} from '../styles';
import { Modal } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import {StyledModal,StyledTitle,StyledBody} from './styles';
import { ImageUrl } from '../../../utils/constants';
import { connect } from "react-redux";
import LazyImage from '../../common/LazyImage/LazyImage';

class MonthPopUp extends React.Component {
  showUpcomingThemes = (data) => {
    const slicedArray =  data.length >= 12 ?  data.slice(0, 12) : data
    return <MoreMonthData>
      <CommonContainer marginTop="1px" padding="0px 10px 10px 10px" float={1} bgColor={'white'}>
        {slicedArray && slicedArray.length>0 && slicedArray.map((item, index) => (
          item.background_color&&item.background_color.length>0?<ThemeCard key={index} padding="15px 7px" bgColor={item.background_color} borderTop={1} justify={1}>
            <ThemeGrid gridWidth="auto">
              {item.theme_icon && item.theme_icon.length>0?<LazyImage src={`${ImageUrl}/${item.theme_icon}`} width={index === 0 ? '50px' : '50px'} />:""}
            </ThemeGrid>
            <DataThemeGridNew groupActivity={0}>
              <div className="new_theme" style={{ paddingLeft: "15px" }}>
                {`${item.month} ${item.year}`}
              </div>
              <DataThemeGrid>
                <div className="theme">{item.theme_text}</div>
              </DataThemeGrid>
            </DataThemeGridNew>
          </ThemeCard>:''
        ))
        }
      </CommonContainer>
    </MoreMonthData>;
  }



  showNewUpcomingThemes = (data) => (
    (data && data.length > 0 ?
      <BirthdayContainerNew marginTop={'marginTop'} style={{height:"95%",overflowY:"scroll"}}>
        {this.showUpcomingThemes(data)}
      </BirthdayContainerNew> : '')
  );


  render() {
    const { showModal,onClose,showModalPopUp ,/*UpcomingThemes,*/themesHeader} = this.props;
    return (
      <StyledModal transform={1} show={showModal}   open={() =>showModalPopUp()}  newWidth={"500px"} newHeight="600px" newBg={'newBg'}>
        <Modal.Header closeButton onHide={() => onClose()} style={{background:"#005c87",borderRadius:"6px 6px 0px 0px"}}>
          <StyledTitle style={{color:"white"}}>Monthly Theme</StyledTitle>
          <LazyImage div={'cross_img'} onClick={() => onClose()} src={ImageUrl + "/images/AdminEngagement/Cross_Icon_Img.png"} alt="close" />
        </Modal.Header>
        <StyledBody>
          <div style={{height:"95%"}}> 
            {this.showNewUpcomingThemes(themesHeader)}
          </div>
        </StyledBody>
      </StyledModal>    
    ) ;
  }

}  
MonthPopUp.propTypes = {
  showModal: PropTypes.bool.isRequired,
  showModalPopUp: PropTypes.func,
  onClose: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  UpcomingThemes: PropTypes.array,
  themesHeader: PropTypes.array,
  monthData: PropTypes.func
}

const mapStateToProps = (state) => ({
  UpcomingThemes: state.profileData.UpcomingThemes,
  themesHeader: state.profileData.themesHeaderAll,
});

export default connect(mapStateToProps, null)(MonthPopUp);